<template>
    <div class="container">
        <div class="body">
            <div class="title">版本服务</div>
            <div class="record" @click="routerChange(3)">订购记录</div>
            <div class="version">
                <div class="version-item">
                    <div class="title">年费版本</div>
                    <div class="tag">适合成长型机构经营</div>
                    <div class="item-list">
                        <ul class="content">
                            <li>支持多种形式</li>
                            <li>覆盖</li>
                            <li>快速实现</li>
                        </ul>
                    </div>
                    <div class="bottom">
                        <div class="price">¥5999<span class="month">/年</span></div>
                        <div class="btn" @click="routerChange(1)">开通年费版</div>
                    </div>
                </div>
                <div class="version-item">
                    <div class="title color">三年及以上版本</div>
                    <div class="tag">适合成熟机构体系化经营</div>
                    <div class="item-list">
                        <ul class="content">
                            <li>支持多种形式</li>
                            <li>覆盖</li>
                            <li>快速实现</li>
                        </ul>
                    </div>
                    <div class="bottom">
                        <div class="price color">¥4999<span class="month">/年</span></div>
                        <div class="btn" @click="routerChange(2)">开通多年版</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
        return {};
    },
    watch: {},
    computed: {},
    created() {},
    mounted() {},
    methods: {
        routerChange(type) {
            switch (type) {
                case 1:
                    return this.$router.push({
                        path: '/system-service/service-detail',
                        query: {
                            type: 10,
                            price: 5999
                        }
                    });
                case 2:
                    return this.$router.push({
                        path: '/system-service/service-detail',
                        query: {
                            type: 20,
                            price: 4999
                        }
                    });
                case 3:
                    return this.$router.push({
                        path: '/system-service/service-record',
                        query: {}
                    });
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.container {
    .body {
        margin: 30px;
        padding: 20px;
        background: white;
        display: flex;
        flex-direction: column;
        position: relative;
        .title {
            font-size: 16px;
            font-weight: 500;
            color: #363d42;
        }
        .record {
            position: absolute;
            right: 62px;
            top: 51px;
            font-size: 16px;
            font-weight: 400;
            color: #74be5f;

            &:hover {
                cursor: pointer;
            }
        }
        .version {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: 70px;

            .version-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 370px;
                height: 648px;
                margin-right: 22px;
                border: 1px solid #e5e5e5;
                border-radius: 9px;
                position: relative;

                .title {
                    font-size: 30px;
                    font-weight: 500;
                    color: #586166;
                    width: 370px;
                    height: 120px;
                    text-align: center;
                    line-height: 120px;
                    background: linear-gradient(133deg, #e9f1fd, #c3cfd9 98%);
                    &.color {
                        background: linear-gradient(
                            133deg,
                            #f3e0b3,
                            #ddb679 98%
                        );
                        color: #7e541f;
                    }
                }

                .tag {
                    font-size: 16px;
                    font-weight: 400;
                    color: #333333;
                    margin-top: 22px;
                }

                .item-list {
                    margin-top: 47px;
                    margin-right: 120px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #666666;
                    .content li {
                        list-style-type: disc;
                        padding: 10px;
                    }
                }

                .bottom {
                    position: absolute;
                    bottom: 42px;
                    text-align: center;
                    .price {
                        font-size: 38px;
                        font-weight: 500;
                        color: #7b8faf;
                        margin-bottom: 20px;
                        .month {
                            font-size: 16px;
                        }

                        &.color {
                            color: #7e541f;
                        }
                    }
                    .btn {
                        width: 240px;
                        height: 42px;
                        border: 1px solid #333333;
                        border-radius: 23px;
                        font-size: 16px;
                        font-weight: 400;
                        color: #333333;
                        line-height: 42px;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}
</style>